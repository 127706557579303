<template>
  <div class="app-container">
    <el-card>
      <student-card></student-card>
    </el-card>
  </div>
</template>

<script>
import * as api from "@/api/users";
import StudentCard from "./components/StudentCard.vue";

export default {
  components: {
    StudentCard,
  },
  data() {
    return {
      activeName: "student",
    };
  },
  created() {
    // this.loadData();
    // this.loadData1();
    // // 院系接口
    // api
    //   .getSystemList({
    //     page: 1,
    //     pageSize: 10,
    //   })
    //   .then((res) => {
    //     this.systemList = res.data.records;
    //   });
  },
  methods: {
    handleClick(val) {
      console.log(val);
    },
    // 切换系部
    changeSystem(id) {
      api.getCardArgumentDetail({ depid: id }).then((res) => {
        let classList = res.data.records.filter((item) => item.depid === id);
        this.classList = classList;
        this.classid = null;
        if (classList != null && classList.length > 0) {
          this.classid = classList[0].id;
        }
      });
    },
    // 切换班级
    changeClass(id) {
      this.classid = id;
    },
    // 搜索学生
    goSearch(form) {
      form.page = 1;
      form.pageSize = 10;
      api.getUserList(form).then((res) => {
        if (res.status == 1) {
          this.studentData = res.data.rows;
          this.studentTotal = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 搜索班级
    goSearchClass(classform) {
      classform.page = 1;
      classform.pageSize = 10;
      api.getClassCardList(classform).then((res) => {
        if (res.status == 1) {
          this.classData = res.data.rows;
          this.classTotal = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 学生或班级
    agreeChange(val) {
      if (val == 0) {
        this.loadData();
      } else {
        this.loadData1();
      }
    },
    // 班级列表
    loadData1() {
      api
        .getClassCardList({
          page: this.page,
          pageSize: this.pageSize,
        })
        .then((res) => {
          if (res.status == 1) {
            this.classData = res.data.rows;
            this.classTotal = res.data.total;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    // 页容量
    handleSizeChange(val, bool) {
      this.pageSize = val;
      this.isClass = bool;
      bool ? this.loadData1() : this.loadData();
    },
    // 当前页
    handleCurrentChange(val, bool) {
      this.page = val;
      this.isClass = bool;
      bool ? this.loadData1() : this.loadData();
    },

    loadData() {
      api.getUserList({
        page: this.page,
        pageSize: this.pageSize,
        classnum: "",
        identity: 0,
      })
        .then((res) => {
          if (res.status == 1) {
            this.studentData = res.data.rows;
            this.studentTotal = res.data.total;
          } else {
            this.$message.error(res.msg);
          }
        });
      // api
      //   .getRoleList({
      //     page: 1,
      //     pageSize: 10,
      //     classnum: "",
      //   })
      //   .then((res) => {
      //     // console.log(res,'角色');
      //     this.roleList = res.data.records;
      //   });
      // this.roleList = JSON.parse(sessionStorage.getItem("menuList"));
      // api
      //   .getSystemList({
      //     page: 1,
      //     pageSize: 10,
      //     classnum: "",
      //     roleid: "",
      //   })
      //   .then((res) => {
      //     this.systemList = res.data.records;
      //   });
    },
    // 关闭弹窗
    handleClose(done) {
      done();
    },
    // handleEdit(row) {
    //   this.getRole();
    //   this.form.id = row.id;
    //   this.roles.roleName = row.role_name;
    //   this.dialogFormVisible = !this.dialogFormVisible;
    // },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-tabs__item.is-active {
  font-weight: bold;
  font-size: 15px;
}

/deep/ .el-container {
  width: 100%;
  height: 100% !important;
}

/deep/.el-dialog__title {
  display: flex;
  flex-direction: row;
}

/deep/.el-dialog__body {
  padding: 0px;
  text-align: center;
}</style>
